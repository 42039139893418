import { Component, OnInit } from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'about-donation',
  templateUrl: './about-donation.component.html',
  styleUrls: ['./about-donation.component.scss', '../about.component.scss', '../../app.component.scss']
})
export class AboutDonationComponent implements OnInit {

  faPhone = faPhone;

  constructor() { }

  ngOnInit(): void {
  }

}
