import { Component, OnInit } from '@angular/core';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'our-mission',
  templateUrl: './our-mission.component.html',
  styleUrls: ['./our-mission.component.scss', '../about.component.scss', '../../app.component.scss']
})
export class OurMissionComponent implements OnInit {

  faPlayCircle = faPlayCircle;
  faCheckCircle = faCheckCircle;

  constructor() { }

  ngOnInit(): void {
  }

}
