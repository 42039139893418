import { Component, HostListener, OnInit } from '@angular/core';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss', '../app.component.scss']
})
export class NavbarComponent implements OnInit {

  faHeart = faHeart;
  isSticky: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (window.innerHeight <= 576) {
      this.isSticky = true;
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (window.innerHeight >= 576) {
      this.isSticky = window.pageYOffset >= 60;
    }
  }
}
