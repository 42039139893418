import { Component, OnInit } from '@angular/core';
import { faChild } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss', '../about.component.scss', '../../app.component.scss']
})
export class WhatWeDoComponent implements OnInit {

  faGraduationCap = faGraduationCap;
  faHandHoldingHeart = faHandHoldingHeart;
  faChild = faChild;
  faChalkboardTeacher = faChalkboardTeacher;

  constructor() { }

  ngOnInit(): void {
  }

}
