<div class="top-bar-area inc-pad bg-dark text-light">
  <div class="container-full">
    <div class="row align-center">
      <div class="col-lg-6 info">
        <ul>
          <li>
            <fa-icon [icon]="faEnvelopeOpen"></fa-icon> <a href = "mailto: fondacija.tadz@gmail.com">fondacija.tadz@gmail.com</a>
          </li>
          <li>
            <fa-icon [icon]="faPhone"></fa-icon> <a href="tel:+387 61 941 061">+387 61 941 061</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 text-right item-flex">
        <!-- <div class="info">
          <ul>
            <li>
              <a href="#">Login</a>
            </li>
            <li>
              <a href="#">Register</a>
            </li>
          </ul>
        </div> -->
        <div class="social">
          <ul>
            <li>
              <a href="https://www.facebook.com/Stipendiranje-budu%C4%87ih-hafiza-789735661383506" target="_blank">
                <fa-icon [icon]="faFacebookF" ></fa-icon>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/stipendiranjebuducihhafiza/?hl=h" target="_blank">
                <fa-icon [icon]="faInstagram"></fa-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
