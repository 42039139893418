import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss', '../app.component.scss']
})
export class BannerComponent implements OnInit {

  innerHeight: string;
  indicatorsTop: string;
  containerTop: string;

  constructor() { }

  ngOnInit(): void {
    this.innerHeight = (window.innerHeight - 99).toString() + 'px';
    this.indicatorsTop = (window.innerHeight).toString() + 'px';
    this.containerTop = (window.innerHeight / 4).toString() + 'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.innerHeight = (event.target.innerHeight - 99) + 'px';
    // this.indicatorsTop = (event.target.innerHeight) + 'px';
    // this.containerTop = (event.target.innerHeight / 4).toString() + 'px';
  }
}
