import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IProjectsViewModel } from 'src/app/app.models';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'donate-details',
  templateUrl: './donate-details.component.html',
  styleUrls: ['./donate-details.component.scss', '../../app.component.scss', '../donate.component.scss', '../../projects/project-details/project-details.component.scss', '../../projects/projects.component.scss']
})
export class DonateDetailsComponent implements OnInit {

  faHome = faHome;
  faCalendarAlt = faCalendarAlt;
  project: IProjectsViewModel;

  projectList: IProjectsViewModel[] = [
    {
      id: 0,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Stipendiranje budućih hafiza",
      shortDescription: "Projekat je osnovan sa ciljem da motivišemo što više učenika da uče Allahov govor i na taj način promovišemo pozitivne vrijednosti u društvu. Kroz projekat nastojimo pružiti financijsku podršku u vidu stipendije ali i moralnu podršku učenicima širom BiH, koja će biti njihov stimulans na putu hifza!",
      description: "Kandidati šalju prijavu za hifz popunjavanjem prijavnog obrasca. Prilikom prijema kandidata, prednost imaju učenici koji već od ranije uče hifz ili po preporuci svog mentora/muhaffiza. Kandidat uči hifz pred svojim mentorom/muhaffizom, a također se obavezuje doći svaka 3 mjeseca u Sarajevo u prostorije Fondacije na provjeru znanja. Uz mjesečnu stipendiju, nakon završetka hifza kandidatu će biti uručena posebna nagrada.",
      motivationMessage: "Ovaj projekat isključivo se finansira od dobrovoljnih priloga. Povećanjem našeg fonda svaki put biramo nove kandidate.",
      types: [
        {
          id: 0,
          description: "Kandidat bira jedan od ponuđenih programa, a prema svojim mogućnostima:",
          types: [
            "Hifz u trajanju od 2 godine",
            "Hifz u trajanju od 3 godine",
            "Hifz u trajanju od 4 godine",
            "Hifz u trajanju od 5 godina"
          ]
        }
      ],
      tags: ["Donacije", "Stipendiranje", "Budući hafizi", "Tim Tadž"],
      imageUrl: "assets/img/stipendiranje-listing.jpg",
      donations: [
        {
          id: 0,
          name: "Pozivom na broj:",
          description: "donirate 2 KM za stipendiranje",
          code: "090 291 064",
          swiftCode: ""
        },
        {
          id: 1,
          name: "Paypal:",
          description: "Sa opcijom za prijatelje",
          code: "stipendiranjebosna@gmail.com",
          swiftCode: ""
        },
        {
          id: 2,
          name: "Uplate za BiH:",
          description: "UniCredit Bank BiH",
          code: "3387 3022 1004 0238",
          swiftCode: ""
        },
        {
          id: 3,
          name: "Uplate iz inostranstva:",
          description: "UniCredit Bank",
          code: "BA39 3387 3048 1004 1624",
          swiftCode: "UNCRBA 22"
        },
        {
          id: 4,
          name: "Uplate vršiti sa naznakom:",
          description: "Projekat Tadž",
          code: "",
          swiftCode: ""
        },
        {
          id: 5,
          name: "Primalac:",
          description: "Fondacija Tadž Marka Marulića 20, Sarajevo Bosna i Hercegovina",
          code: "",
          swiftCode: ""
        }
      ]
    },
    {
      id: 1,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Džeparac za školarce",
      shortDescription: "Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji odrastaju u težim finansijskim uslovima. Podržite ovaj projekat zajedno sa nama, zajedno možemo promijeniti nečiji svijet!",
      description: "Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji žive u teškim uslovima. Cilj je pomoći socijalno-ugroženu djecu, djecu bez roditeljskog staranja, djecu u teškoj finansijskoj situaciji, a koja pohađaju osnovnu ili srednju školu. ",
      motivationMessage: `Stoga smatramo da je veliki hajr u pomaganju svih ljudi, a naročito onih koji su u potrebi i u oskudici, odatle crpimo motivaciju za sve naše humanitarne projekte.
        U projekat je uključeno nekoliko osnovnih škola iz Bosne i Hercegovine, te gotovo 100 učenika kojima ove školske godine želimo obezbjediti mjesečni džeparac.
        Podržite ovaj projekat zajedno sa nama!
        Zajedno možemo promijeniti nečiji svijet!`,
        types: [
          {
            id: 0,
            description: "Finansiranje školaraca vrši se jednom mjesečno za vrijeme trajanja školske godine (od septembra do juna) i to na slijedeći način:",
            types: ["Džeparac za osnovnoškolce – 35 KM (18 €)", "Džeparac za srednjoškolce – 45 KM (23 €) "]
          },
          {
            id: 1,
            description: "U cilju podrške ovog projekta, moguće je uplatiti:",
            types: ["Mjesečni džeparac", "Džeparac za 3 mjeseca", "Džeparac za 6 mjeseci", "Džeparac za čitavu školsku godinu"]
          }
        ],
      tags: ["Donacije", "Škola", "Džaci", "Džeparac"],
      imageUrl: "assets/img/dzeparacZaSkolarceListing.jpg",
      donations: [
        {
          id: 0,
          name: "",
          description: "",
          code: "",
          swiftCode: ""
        }
      ]
    },
    {
      id: 2,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Pomoć za sve",
      shortDescription: "Projekat je osnovan prvenstveno zbog pružanja pomoći najugroženijoj kategoriji stanovništva: siromašnoj djeci, samohranim majkama, porodicama u potrebi, bolesnim, gladnim, iznemoglim, učenicima u potrebi, onima kojima je pomoć potrebna!",
      description: "Ovaj fond je osnovan prvenstveno zbog pomoći najugroženijoj i najpotrebnijoj kategoriji stanovništva, a pogotovo siromašnoj djeci. Nakon zadovoljenja primarne funkcije ovog Fonda, a to je pomaganje najugroženijih, višak iz ovog Fonda se odvaja za troškove Fondacije - administrativne troškove (računovođe, prostor itd).",
      motivationMessage: "Ovaj projekat isključivo se finansira od dobrovoljnih priloga. Povećanjem našeg fonda svaki put biramo nove kandidate.",
      types: [
        {
          id: 0,
          description: "Uplate za ovaj fond su raspoređene za pomaganje:",
          types: [
            "Siromašne djece",
            "Porodica u potrebi",
            "Samohranih majki",
            "Bolesnih",
            "Gladnih",
            "Iznemoglih",
            "Učenika i studenata u potrebi",
            "Migranata",
            "Onima kojima je pomoć potrebna",
            "Za sve ostale humanitarne akcije"
          ]
        }
      ],
      tags: ["Donacije", "Pomoć za sve"],
      imageUrl: "assets/img/pomocZaSveListing.jpg",
      donations: [
        {
          id: 0,
          name: "Pozivom na broj:",
          description: "donirate 3 KM za socijalno ugroženo stanovništvo",
          code: "090 292 005",
          swiftCode: ""
        },
        {
          id: 1,
          name: "Paypal:",
          description: "Sa opcijom za prijatelje",
          code: "stipendiranjebosna@gmail.com",
          swiftCode: ""
        },
        {
          id: 2,
          name: "Uplate za BiH:",
          description: "UniCredit Bank BiH",
          code: "3387 3022 1004 0238",
          swiftCode: ""
        },
        {
          id: 3,
          name: "Uplate iz inostranstva:",
          description: "UniCredit Bank",
          code: "BA39 3387 3048 1004 1624",
          swiftCode: "UNCRBA 22"
        },
        {
          id: 4,
          name: "Uplate vršiti sa naznakom:",
          description: "Pomoć za sve",
          code: "",
          swiftCode: ""
        },
        {
          id: 5,
          name: "Primalac:",
          description: "Fondacija Tadž Marka Marulića 20, Sarajevo Bosna i Hercegovina",
          code: "",
          swiftCode: ""
        }
      ]
    }
  ]

  constructor(private readonly activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadProject();
  }

  loadProject (): void {
    this.activatedRoute.params.subscribe(params => {
        let name = params['name'];
        this.project = this.projectList.find(x => x.name === name);
    });
}

}
