<div class="breadcrumb-area text-center shadow dark bg-fixed-projects padding-xl text-light" style="background-image: url(assets/img/projektiBanner.jpg);">
  <div class="container">
      <div class="breadcrumb-items">
          <div class="row">
              <div class="col-lg-12">
                  <h2>Projekti</h2>
              </div>
          </div>
          <ul class="breadcrumb">
              <li><a routerLink="" routerLinkActive="active"><fa-icon [icon]="faHome"></fa-icon> Početna</a></li>
              <li>Projekti</li>
              <!-- <li class="active">Standard</li> -->
          </ul>
      </div>
  </div>
</div>

<div class="blog-area full-blog blog-standard full-blog default-padding">
  <div class="container">
      <div class="blog-items">
          <div class="row">
              <div class="blog-content col-lg-10 offset-lg-1 col-md-12">
                  <div class="blog-item-box">
                      <!-- Single Item -->

                      <div class="single-item" *ngFor="let project of projectList">
                        <projects-row-entry [project]="project"></projects-row-entry>
                          <!-- <div class="item wow fadeInUp">
                              <div class="thumb">
                                  <a href="#">
                                      <img src="assets/img/1500x700.png" alt="Thumb">
                                  </a>
                              </div>
                              <div class="info">
                                  <div class="tags">
                                      <a>Donacije</a>
                                      <a>Stipendiranje</a>
                                      <a>Budući hafizi</a>
                                      <a>Tim Tadž</a>
                                  </div>
                                  <div class="meta">
                                      <ul>
                                          <li><i class="fas fa-calendar-alt"></i> 18 Nov, 2020</li>
                                          <li><a>Fondacija Tadž</a></li>
                                      </ul>
                                  </div>
                                  <h4>
                                      <a>Stipendiranje budućih hafiza</a>
                                  </h4>
                                  <p>
                                    Projekat je osnovan sa ciljem da motivišemo što više učenika da uče Allahov govor i na taj način promovišemo pozitivne vrijednosti u društvu.
                                    Kroz projekat nastojimo pružiti financijsku podršku u vidu stipendije ali i moralnu podršku učenicima širom BiH, koja će biti njihov stimulans na putu hifza.
                                  </p>
                                  <a class="btn circle btn-theme border btn-md" href="#">Više o projektu<i class="fas fa-long-arrow-alt-right"></i></a>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Single Item -->
                      <!-- Single Item -->
                      <!-- <div class="single-item">
                          <div class="item wow fadeInUp">
                              <div class="thumb">
                                  <a href="#">
                                      <img src="assets/img/1500x700.png" alt="Thumb">
                                  </a>
                              </div>
                              <div class="info">
                                  <div class="tags">
                                    <a>Donacije</a>
                                    <a>Škola</a>
                                    <a>Džaci</a>
                                    <a>Džeparac</a>
                                  </div>
                                  <div class="meta">
                                      <ul>
                                          <li><i class="fas fa-calendar-alt"></i> 05 Dec, 2020</li>
                                          <li><a>Fondacija Tadž</a></li>
                                      </ul>
                                  </div>
                                  <h4>
                                      <a>Džeparac za školarce</a>
                                  </h4>
                                  <p>
                                    Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji odrastaju u težim finansijskim uslovima.

                                    Podržite ovaj projekat zajedno sa nama,
                                    zajedno možemo promijeniti nečiji svijet!
                                  </p>
                                  <a class="btn circle btn-theme border btn-md">Više o projektu<i class="fas fa-long-arrow-alt-right"></i></a>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Single Item -->
                      <!-- Single Item -->
                      <!-- <div class="single-item">
                          <div class="item wow fadeInUp">
                              <div class="thumb">
                                  <a href="#">
                                      <img src="assets/img/1500x700.png" alt="Thumb">
                                  </a>
                              </div>
                              <div class="info">
                                  <div class="tags">
                                    <a>Donacije</a>
                                    <a>Pomoć za sve</a>
                                  </div>
                                  <div class="meta">
                                      <ul>
                                          <li><i class="fas fa-calendar-alt"></i> 26 Sep, 2020</li>
                                          <li><a>Fondacija Tadž</a></li>
                                      </ul>
                                  </div>
                                  <h4>
                                      <a>Pomoć za sve</a>
                                  </h4>
                                  <p>
                                    Projekat je osnovan prvenstveno zbog pružanja pomoći najugroženijoj kategoriji stanovništva:
                                    siromašnoj djeci, samohranim majkama, porodicama u potrebi, bolesnim, gladnim, iznemoglim, učenicima u potrebi, onima kojima je pomoć potrebna!
                                  </p>
                                  <a class="btn circle btn-theme border btn-md">Više o projektu<i class="fas fa-long-arrow-alt-right"></i></a>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Single Item -->
                  </div>

                  <!-- Pagination -->
                  <!-- <div class="row">
                      <div class="col-md-12 pagi-area text-center">
                          <nav aria-label="navigation">
                              <ul class="pagination">
                                  <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-left"></i></a></li>
                                  <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                                  <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
                              </ul>
                          </nav>
                      </div>
                  </div> -->
              </div>
          </div>
      </div>
  </div>
</div>

<!-- <project-details></project-details> -->
