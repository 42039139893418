<!-- Start What We Do
    ============================================= -->
<div class="we-do-area half-bg default-padding bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="site-heading text-center">
          <h5>Čime se bavimo</h5>
          <h2>
            Pomažemo one kojima je <br> pomoć potrebna.
          </h2>
          <div class="heading-divider"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="wedo-items text-center">
      <div class="row">
        <!-- Single Item -->
        <div class="single-item col-lg-3 col-md-6">
          <div class="item">
            <fa-icon [icon]="faGraduationCap" ></fa-icon>
            <h4>Stipendiranje budućih hafiza</h4>
            <!-- <p>
              Misija Fondacije jeste pružiti pomoć onima koji uče Kur’an napamet
              , te na taj način pomoći, probuditi i
              promovisati opšte pozitivne vrijednosti u društvu.
            </p> -->
            <p>
              Misija Fondacije jeste pružiti pomoć onima koji uče hifz.
            </p>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="single-item col-lg-3 col-md-6">
          <div class="item">
            <fa-icon [icon]="faHandHoldingHeart" ></fa-icon>
            <h4>Pomoć za sve</h4>
            <p>
              Projekat je osnovan prvenstveno zbog pružanja pomoći najugroženijoj kategoriji stanovništva.
            </p>
            <!-- <p>
              Projekat je osnovan prvenstveno zbog pružanja pomoći najugroženijoj kategoriji stanovništva:
              siromašnoj djeci, samohranim majkama, porodicama u potrebi, bolesnim, gladnim, iznemoglim, učenicima u
              potrebi, onima kojima je pomoć potrebna!
            </p> -->
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="single-item col-lg-3 col-md-6">
          <div class="item">
            <fa-icon [icon]="faChild" ></fa-icon>
            <h4>Džeparac za školarce</h4>
            <p>
              Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola.
            </p>
            <!-- <p>
              Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji odrastaju u težim
              finansijskim uslovima.
            </p> -->
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="single-item col-lg-3 col-md-6">
          <div class="item">
            <fa-icon [icon]="faChalkboardTeacher" ></fa-icon>
            <h4>Promovisanje pozitivnih vrijednosti</h4>
            <p>
              Kroz druženja, predavanja, radionice i druge korisne sadržaje.
            </p>
          </div>
        </div>
        <!-- End Single Item -->
      </div>
    </div>
  </div>
</div>
<!-- End What We Do -->
