import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'about-tadz',
  templateUrl: './about-tadz.component.html',
  styleUrls: ['./about-tadz.component.scss', '../about.component.scss', '../../app.component.scss']
})
export class AboutTadzComponent implements OnInit {

  faQuoteLeft = faQuoteLeft;
  customOptions: OwlOptions;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.customOptions = {
      loop: false,
              margin: 30,
              nav: false,
              autoplayTimeout: 10000,
              navText: [
                  "<i class='fa fa-angle-left'></i>",
                  "<i class='fa fa-angle-right'></i>"
              ],
              dots: true,
              autoplay: true,
              responsive: {
                  0: {
                      items: 1
                  },
                  800: {
                      items: 2
                  },
                  1000: {
                      items: 2
                  }
              }
    }
  //   document.getElementsByClassName('.testimonial-carousel').owlCarousel({
  //     loop: false,
  //     margin: 30,
  //     nav: false,
  //     navText: [
  //         "<i class='fa fa-angle-left'></i>",
  //         "<i class='fa fa-angle-right'></i>"
  //     ],
  //     dots: true,
  //     autoplay: true,
  //     responsive: {
  //         0: {
  //             items: 1
  //         },
  //         800: {
  //             items: 2
  //         },
  //         1000: {
  //             items: 2
  //         }
  //     }
  // });
  }

}
