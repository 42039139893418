<!-- Start Breadcrumb
    ============================================= -->
    <div class="breadcrumb-area text-center shadow dark bg-fixed-contact padding-xl text-light" style="background-image: url(assets/img/kontaktBanner.jpg);">
      <div class="container">
          <div class="breadcrumb-items">
              <div class="row">
                  <div class="col-lg-12">
                      <h2>Kontakt</h2>
                  </div>
              </div>
              <ul class="breadcrumb">
                  <li><a routerLink="" routerLinkActive="active"><fa-icon [icon]="faHome"></fa-icon> Početna</a></li>
                  <li>Kontakt</li>
                  <!-- <li class="active">Contact</li> -->
              </ul>
          </div>
      </div>
  </div>
  <!-- End Breadcrumb -->

  <!-- Start Contact
  ============================================= -->
  <div class="contact-form-area default-padding">
      <div class="container">
          <div class="row align-center">
              <!-- Start Contact Form -->
              <div class="col-lg-7 contact-forms">
                  <div class="content">
                      <div class="heading">
                          <h3>Kontaktirajte nas</h3>
                      </div>
                      <!-- <form action="assets/mail/contact.php" method="POST" class="contact-form">
                          <div class="row">
                              <div class="col-lg-12">
                                  <div class="form-group">
                                      <input class="form-control" id="name" name="name" placeholder="Name" type="text">
                                      <span class="alert-error"></span>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12">
                                  <div class="form-group">
                                      <input class="form-control" id="email" name="email" placeholder="Email*" type="email">
                                      <span class="alert-error"></span>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12">
                                  <div class="form-group">
                                      <input class="form-control" id="phone" name="phone" placeholder="Phone" type="text">
                                      <span class="alert-error"></span>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12">
                                  <div class="form-group comments">
                                      <textarea class="form-control" id="comments" name="comments" placeholder="Tell Us About Project *"></textarea>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12">
                                  <button type="submit" name="submit" id="submit">
                                      Send Message <i class="fa fa-paper-plane"></i>
                                  </button>
                              </div>
                          </div>
                          Alert Message
                          <div class="col-md-12 alert-notification">
                              <div id="message" class="alert-msg"></div>
                          </div>
                      </form> -->
                  </div>
              </div>
              <!-- End Contact Form -->
              <div class="col-lg-5 address-info">
                  <div class="address-items">
                      <ul>
                          <li>
                              <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                              <p>
                                  Naša lokacija
                                  <span>Marka Marulića 20, 71000 Sarajevo <br> Bosna i Hercegovina</span>
                              </p>
                          </li>
                          <li>
                              <fa-icon [icon]="faEnvelopeOpen"></fa-icon>
                              <p>
                                  Mail
                                  <span><a href = "mailto: fondacija.tadz@gmail.com">fondacija.tadz@gmail.com</a></span>
                              </p>
                          </li>
                          <li>
                              <fa-icon [icon]="faMobileAlt"></fa-icon>
                              <p>
                                  Broj telefona
                                  <span><a href="tel:+387 61 941 061">+387 61 941 061</a></span>
                              </p>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- End Contact -->

  <!-- Start Google Maps
  ============================================= -->
  <div class="maps-area">
      <div class="google-maps">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.757700284978!2d18.38773931572794!3d43.86085054699584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758c93bb7e1cc31%3A0x8a3dbf3865d4ed67!2sMarka%20Marulica%2020%2C%20Sarajevo%2071000!5e0!3m2!1shr!2sba!4v1635633907912!5m2!1shr!2sba" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
  </div>
  <!-- End Google Maps -->
