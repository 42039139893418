import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  mobile: boolean;
  innerWidth: any;
  innerHeight: any;

  constructor (
    private readonly router: Router
  ) {

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.checkSize();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  private checkSize (): void {
    if (this.innerWidth < 576) {
      this.mobile = false;
    } else if (this.innerWidth >= 576 && this.innerWidth < 768) {
      this.mobile = true;
    } else if (this.innerWidth >= 768 && this.innerWidth < 992) {
      this.mobile = true;
    } else if (this.innerWidth >= 992 && this.innerWidth < 1200) {
      this.mobile = true;
    } else if (this.innerWidth >= 1200 && this.innerWidth < 1600) {
      this.mobile = true;
    } else {
      this.mobile = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    this.checkSize();
  }
}
