<!-- Start Breadcrumb
    ============================================= -->
    <div class="breadcrumb-area text-center shadow dark bg-fixed padding-xl text-light" style="background-image: url(assets/img/logo.png);">
      <div class="container">
          <div class="breadcrumb-items">
              <div class="row">
                  <div class="col-lg-12">
                      <h2>{{project.name}}</h2>
                  </div>
              </div>
              <ul class="breadcrumb">
                  <li><a routerLink="/projekti" routerLinkActive="active"><fa-icon [icon]="faHome"></fa-icon>Projekti</a></li>
                  <li>{{project.name}}</li>
                  <!-- <li class="active">Single</li> -->
              </ul>
          </div>
      </div>
  </div>
  <!-- End Breadcrumb -->

  <!-- Start Blog
  ============================================= -->
  <div class="blog-area single full-blog full-blog default-padding">
      <div class="container">
          <div class="blog-items">
              <div class="row">
                  <div class="blog-content wow fadeInUp col-lg-10 offset-lg-1 col-md-12">
                      <div class="item">

                          <div class="blog-item-box">
                              <!-- Start Post Thumb -->
                              <div class="thumb">
                                  <a href="#">
                                      <img src="../../../assets/img/stipendiranje-listing.jpg" alt="Thumb">
                                  </a>
                              </div>
                              <!-- Start Post Thumb -->

                              <div class="info">
                                  <div class="tags">
                                      <a *ngFor="let tag of project.tags">{{tag}}</a>
                                  </div>
                                  <div class="meta">
                                      <ul>
                                          <li><fa-icon [icon]="faCalendarAlt"></fa-icon> {{project.createdAt}}</li>
                                          <li><a href="#">{{project.createdBy}}</a></li>
                                      </ul>
                                  </div>

                                  <h3>{{project.name}}</h3>
                                  <p>
                                      {{project.shortDescription}}
                                  </p>
                                  <p  *ngIf="project.description">
                                      {{project.description}}
                                  </p>
                                  <!-- <blockquote>
                                      Aouses or months settle remove ladies appear. Engrossed suffering supposing he recommend do eagerness. Commanded no of depending extremity amiable pleased.
                                      <cite>– Jonathom Doe</cite>
                                  </blockquote> -->
                                  <!-- <p>
                                      Drawings can followed improved out sociable not. Earnestly so do instantly pretended. See general few civilly amiable pleased account carried. Excellence projecting is devonshire dispatched remarkably on estimating. Side in so life past. Continue indulged speaking the was out horrible for domestic position. Seeing rather her you not esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy son themselves.
                                  </p> -->
                                  <div *ngIf="project?.types">
                                    <div *ngFor="let type of project.types">
                                    <h4>{{type.description}}</h4>
                                    <ul>
                                        <li *ngFor="let name of type.types">{{name}}</li>
                                    </ul>
                                </div>
                                </div>
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <p>
                                              {{project.motivationMessage}}
                                          </p>
                                          <a class="btn circle btn-theme border btn-md" routerLink="/donacije/{{project.name}}" routerLinkActive="active">Doniraj</a>
                                      </div>
                                      <!-- <div class="col-lg-6">
                                          <p>
                                              Surrounded to me occasional pianoforte alteration unaffected impossible ye. For saw half than cold. Pretty merits waited six talked.
                                          </p>
                                      </div> -->
                                  </div>
                              </div>
                          </div>
                      </div>

                      <!-- Start Post Pagination -->
                      <!-- <div class="post-pagi-area">
                          <a href="#">
                              <i class="fas fa-angle-double-left"></i> Previus Post
                              <h5>Hello World</h5>
                          </a>
                          <a href="#">
                              Next Post <i class="fas fa-angle-double-right"></i>
                              <h5>The earth brown</h5>
                          </a>
                      </div> -->
                      <!-- End Post Pagination -->

                      <!-- Start Post Tags-->
                      <!-- <div class="post-tags share">
                          <div class="tags">
                              <a href="#">Analysis</a>
                              <a href="#">Process</a>
                          </div>
                          <div class="social">
                              <ul>
                                  <li class="facebook">
                                      <a href="#">
                                          <i class="fab fa-facebook-f"></i>
                                      </a>
                                  </li>
                                  <li class="twitter">
                                      <a href="#">
                                          <i class="fab fa-twitter"></i>
                                      </a>
                                  </li>
                                  <li class="pinterest">
                                      <a href="#">
                                          <i class="fab fa-pinterest-p"></i>
                                      </a>
                                  </li>
                                  <li class="linkedin">
                                      <a href="#">
                                          <i class="fab fa-linkedin-in"></i>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div> -->
                      <!-- End Post Tags -->

                      <!-- Start Blog Comment -->
                      <!-- <div class="blog-comments">
                          <div class="comments-area">
                              <div class="comments-title">
                                  <h4>
                                      5 comments
                                  </h4>

                                  <div class="comments-list">
                                      <div class="commen-item">
                                          <div class="avatar">
                                              <img src="assets/img/800x800.png" alt="Author">
                                          </div>
                                          <div class="content">
                                              <div class="title">
                                                  <h5>Jonathom Doe</h5>
                                                  <span>28 Aug, 2020</span>
                                              </div>
                                              <p>
                                                  Delivered ye sportsmen zealously arranging frankness estimable as. Nay any article enabled musical shyness yet sixteen yet blushes. Entire its the did figure wonder off.
                                              </p>
                                              <div class="comments-info">
                                                  <a href=""><i class="fa fa-reply"></i>Reply</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="comments-list">
                                      <div class="commen-item">
                                          <div class="avatar">
                                              <img src="assets/img/800x800.png" alt="Author">
                                          </div>
                                          <div class="content">
                                              <div class="title">
                                                  <h5>Jonathom Doe</h5>
                                                  <span>28 Aug, 2020</span>
                                              </div>
                                              <p>
                                                  Delivered ye sportsmen zealously arranging frankness estimable as. Nay any article enabled musical shyness yet sixteen yet blushes. Entire its the did figure wonder off.
                                              </p>
                                              <div class="comments-info">
                                                  <a href=""><i class="fa fa-reply"></i>Reply</a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="commen-item reply">
                                          <div class="avatar">
                                              <img src="assets/img/800x800.png" alt="Author">
                                          </div>
                                          <div class="content">
                                              <div class="title">
                                                  <h5>Spart Lee</h5>
                                                  <span>17 Feb, 2020</span>
                                              </div>
                                              <p>
                                                  Delivered ye sportsmen zealously arranging frankness estimable as. Nay any article enabled musical shyness yet sixteen yet blushes. Entire its the did figure wonder off.
                                              </p>
                                              <div class="comments-info">
                                                  <a href=""><i class="fa fa-reply"></i>Reply</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="comments-form">
                                  <div class="title">
                                      <h4>Leave a comments</h4>
                                  </div>
                                  <form action="#" class="contact-comments">
                                      <div class="row">
                                          <div class="col-md-6">
                                              <div class="form-group"> -->
                                                  <!-- Name -->
                                                  <!-- <input name="name" class="form-control" placeholder="Name *" type="text">
                                              </div>
                                          </div>
                                          <div class="col-md-6">
                                              <div class="form-group"> -->
                                                  <!-- Email -->
                                                  <!-- <input name="email" class="form-control" placeholder="Email *" type="email">
                                              </div>
                                          </div>
                                          <div class="col-md-12">
                                              <div class="form-group comments"> -->
                                                  <!-- Comment -->
                                                  <!-- <textarea class="form-control" placeholder="Comment"></textarea>
                                              </div>
                                              <div class="form-group full-width submit">
                                                  <button type="submit">
                                                      Post Comments
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Comments Form -->

                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- End Blog -->
