<div class="mission-area half-bg default-padding-bottom bg-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 thumb-box">
        <div class="thumb">
          <div class="thumb-1">
            <img src="../../../assets/img/nasaMisija.jpg" alt="Thumb">
          </div>
          <div class="thumb-2">
            <img src="../../../assets/img/oNamaPozadina.png" alt="Thumb">
            <a href="../../../assets/videos/oNama.mp4"
              class="popup-youtube light video-play-button item-center">
              <fa-icon [icon]="faPlayCircle"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 info">
        <h5>Naša misija</h5>
        <h2 class="text-blur">Misija</h2>
        <h2 class="area-title">Vjerujemo da zajedno možemo raditi za bolju i ljepšu budućnost.</h2>
        <h4>Realizirani projekti:</h4>
        <ul>
          <li><fa-icon [icon]="faCheckCircle"></fa-icon> Stipendiranje učenika hifza</li>
          <li><fa-icon [icon]="faCheckCircle"></fa-icon> Pomoć socijalno ugroženim porodicama</li>
          <li><fa-icon [icon]="faCheckCircle"></fa-icon> Džeparac za školarce</li>
          <li><fa-icon [icon]="faCheckCircle"></fa-icon> Bajramski/ramazanski paketi</li>
          <li><fa-icon [icon]="faCheckCircle"></fa-icon> Pokrovitelj škole hifza u Žepču</li>
        </ul>
        <!-- <a class="btn circle btn-theme border btn-md" href="#">Join With Us</a> -->
      </div>
    </div>
  </div>
</div>
