import { Component, OnInit } from '@angular/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../app.component.scss']
})
export class HeaderComponent implements OnInit {

  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faEnvelopeOpen = faEnvelopeOpen;
  faPhone = faPhone;

  constructor() { }

  ngOnInit(): void {
  }

}
