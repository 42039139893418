<footer class="bg-dark text-light">
  <!-- Fixed Shape -->
  <div class="fixed-shape">
      <img src="../../assets/img/footer-bg.png" alt="Shape">
  </div>
  <!-- Fixed Shape -->
  <div class="container">
      <div class="f-items default-padding">
          <div class="row">
              <div class="col-lg-4 col-md-6 item">
                  <div class="f-item about">
                      <img src="../../assets/img/logoOriginal.png" alt="Logo">
                      <!-- <p>
                          Happen active county. Winding morning am shyness evident to. Garrets because elderly new manners however one village she.
                      </p>
                      <form action="#">
                          <input type="email" placeholder="Your Email" class="form-control" name="email">
                          <button type="submit"> Subscribe</button>
                      </form> -->
                  </div>
              </div>
              <!-- <div class="col-lg-2 col-md-6 item">
                  <div class="f-item link">
                      <h4 class="widget-title">Explore</h4>
                      <ul>
                          <li>
                              <a href="#">Our Causes</a>
                          </li>
                          <li>
                              <a href="#">New Campaign</a>
                          </li>
                          <li>
                              <a href="#">Site Map</a>
                          </li>
                          <li>
                              <a href="#">Donate</a>
                          </li>
                          <li>
                              <a href="#">Terms</a>
                          </li>
                      </ul>
                  </div>
              </div> -->

              <div class="col-lg-3 col-md-6 item">
                  <div class="f-item">
                      <h4 class="widget-title">Kontakt informacije</h4>
                      <div class="address">
                          <ul>
                              <li>
                                  <strong>Adresa:</strong>
                                  Marka Marulića 20. Sarajevo 71000 <br>
                                  Bosna i Hercegovina
                              </li>
                              <li>
                                  <strong>Email:</strong>
                                  <a href = "mailto: fondacija.tadz@gmail.com">fondacija.tadz@gmail.com</a>
                              </li>
                              <li>
                                  <strong>Telefon:</strong>
                                  <a href="tel:+387 61 941 061">+387 61 941 061</a>
                              </li>
                              <li>
                          </ul>
                      </div>
                  </div>
              </div>

              <!-- <div class="col-lg-3 col-md-6 item">
                  <div class="f-item recent-post">
                      <h4 class="widget-title">Latest News</h4>
                      <div class="item">
                          <a href="#">Delighted prevailed supported too not remainder perpetual.</a>
                          <span><i class="fas fa-calendar-alt"></i> 22 Aug, 2020 -  <a href="#">Admin</a></span>
                      </div>
                      <div class="item">
                          <a href="#">Speaking trifling an to unpacked moderate debating learnin management. </a>
                          <span><i class="fas fa-calendar-alt"></i> 15 Nov, 2020 -  <a href="#">User</a></span>
                      </div>
                  </div>
              </div> -->

          </div>
      </div>
  </div>
  <!-- Start Footer Bottom -->
  <div class="footer-bottom">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <p>Copyright &copy;  2021. <a routerLink="" routerLinkActive="active">Fondacija Tadž</a></p>
              </div>
              <div class="col-md-6 text-right link">
                  <!-- <ul>
                      <li>
                          <a href="#">Terms</a>
                      </li> -->
                        <a routerLink="/privacy">Privacy Policy</a>
                      <!-- <li>
                          <a href="#">Support</a>
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
  </div>
  <!-- End Footer Bottom -->
</footer>
