<!-- Single Item -->
<div class="col-lg-12">
<div class="grid-item">
  <div class="row">
    <div class="thumb col-lg-5" style="background-image: url({{project.imageUrl}});">
      <span class="cats">{{project.name}}</span>
    </div>
    <div class="info col-lg-7">
      <div class="top-entry">
        <div class="date">
          <fa-icon [icon]="faClock"></fa-icon> <strong></strong> Jul 15, 2020
        </div>
        <div class="location">
          <fa-icon [icon]="faMapMarkerAlt"></fa-icon> <strong></strong> Bosna i Hercegovina
        </div>
      </div>
      <h3>
        <a href="#">{{project.name}}</a>
      </h3>
      <p>
        {{project.shortDescription}}
      </p>
      <!-- <div class="progress-box">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" data-width="33">
                                            <span>33%</span>
                                        </div>
                                    </div>
                                    <p>Raised : $5488 <span>Goal : $12800</span></p>
                                </div> -->
      <a class="btn circle btn-theme border btn-md" routerLink="/donacije/{{project.name}}" routerLinkActive="active">Doniraj</a>
    </div>
  </div>
</div>
<!-- End Single Item -->
</div>
