import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavbarComponent } from './navbar/navbar.component';
import { BannerComponent } from './banner/banner.component';
import { DonateComponent } from './donate/donate.component';
import { AboutComponent } from './about/about.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { WhatWeDoComponent } from './about/what-we-do/what-we-do.component';
import { OurMissionComponent } from './about/our-mission/our-mission.component';
import { AboutDonationComponent } from './about/about-donation/about-donation.component';
import { AboutTadzComponent } from './about/about-tadz/about-tadz.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';
import { ProjectsRowEntryComponent } from './projects/projects-row-entry/projects-row-entry.component';
import { DonateRowEntryComponent } from './donate/donate-row-entry/donate-row-entry.component';
import { DonateDetailsComponent } from './donate/donate-details/donate-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    BannerComponent,
    DonateComponent,
    AboutComponent,
    ProjectsComponent,
    ContactComponent,
    WhatWeDoComponent,
    OurMissionComponent,
    AboutDonationComponent,
    AboutTadzComponent,
    FooterBottomComponent,
    ProjectDetailsComponent,
    ProjectsRowEntryComponent,
    DonateRowEntryComponent,
    DonateDetailsComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    IvyCarouselModule,
    CarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
