import { Component, Input, OnInit } from '@angular/core';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { IProjectsViewModel } from 'src/app/app.models';

@Component({
  selector: 'donate-row-entry',
  templateUrl: './donate-row-entry.component.html',
  styleUrls: ['./donate-row-entry.component.scss', '../../app.component.scss']
})
export class DonateRowEntryComponent implements OnInit {

  faClock = faClock;
  faMapMarkerAlt = faMapMarkerAlt;

  @Input()
  project: IProjectsViewModel;

  constructor() { }

  ngOnInit(): void {
  }

}
