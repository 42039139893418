<div class="item wow fadeInUp">
  <div class="thumb">
    <a routerLink="/projekti/{{project.name}}" routerLinkActive="active">
      <img src="{{project.imageUrl}}" alt="Thumb">
    </a>
  </div>
  <div class="info">
    <div class="tags">
      <a *ngFor="let tag of project.tags">{{tag}}</a>
    </div>
    <div class="meta">
      <ul>
        <li><fa-icon [icon]="faCalendarAlt"></fa-icon>{{project.createdAt}}</li>
        <li><a>{{project.createdBy}}</a></li>
      </ul>
    </div>
    <h4>
      <a>{{project.name}}</a>
    </h4>
    <p>
      {{project.shortDescription}}
    </p>
    <a class="btn circle btn-theme border btn-md" routerLink="/projekti/{{project.name}}" routerLinkActive="active">Više o projektu<fa-icon [icon]="faLongArrowAltRight"></fa-icon></a>
  </div>
</div>
