import { IProjectsViewModel } from './../../app.models';
import { Component, Input, OnInit } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'projects-row-entry',
  templateUrl: './projects-row-entry.component.html',
  styleUrls: ['./projects-row-entry.component.scss', '../../app.component.scss']
})
export class ProjectsRowEntryComponent implements OnInit {
  faCalendarAlt = faCalendarAlt;
  faLongArrowAltRight = faLongArrowAltRight;

  @Input()
  project: IProjectsViewModel;

  constructor() { }

  ngOnInit(): void {
  }

}
