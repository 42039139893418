import { Component, OnInit } from '@angular/core';
import { IProjectsViewModel } from '../app.models';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss', '../app.component.scss']
})
export class ProjectsComponent implements OnInit {
  faHome = faHome;

  projectList: IProjectsViewModel[] = [
    {
      id: 0,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Stipendiranje budućih hafiza",
      shortDescription: "Projekat je osnovan sa ciljem da motivišemo što više učenika da uče hifz i na taj način promovišemo pozitivne vrijednosti u društvu. Kroz projekat nastojimo pružiti financijsku podršku u vidu stipendije ali i moralnu podršku učenicima širom BiH, koja će biti njihov stimulans na putu hifza!",
      description: null,
      motivationMessage: "Ovaj projekat isključivo se finansira od dobrovoljnih priloga. Povećanjem našeg fonda svaki put biramo nove kandidate.",
      types: null,
      tags: ["Donacije", "Stipendiranje", "Budući hafizi", "Tim Tadž"],
      imageUrl: "assets/img/stipendiranje-listing.jpg"
    },
    {
      id: 1,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Džeparac za školarce",
      shortDescription: "Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji odrastaju u težim finansijskim uslovima. Podržite ovaj projekat zajedno sa nama, zajedno možemo promijeniti nečiji svijet!",
      description: "Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji žive u teškim uslovima. Cilj je pomoći socijalno-ugroženu djecu, djecu bez roditeljskog staranja, djecu u teškoj finansijskoj situaciji, a koja pohađaju osnovnu ili srednju školu. ",
      motivationMessage: `Stoga smatramo da je veliki hajr u pomaganju svih ljudi, a naročito onih koji su u potrebi i u oskudici, odatle crpimo motivaciju za sve naše humanitarne projekte.
        U projekat je uključeno nekoliko osnovnih škola iz Bosne i Hercegovine, te gotovo 100 učenika kojima ove školske godine želimo obezbjediti mjesečni džeparac.
        Podržite ovaj projekat zajedno sa nama!
        Zajedno možemo promijeniti nečiji svijet!`,
        types: [
          {
            id: 0,
            description: "Finansiranje školaraca vrši se jednom mjesečno za vrijeme trajanja školske godine (od septembra do juna) i to na slijedeći način:",
            types: ["Džeparac za osnovnoškolce – 35 KM (18 €)", "Džeparac za srednjoškolce – 45 KM (23 €) "]
          },
          {
            id: 1,
            description: "U cilju podrške ovog projekta, moguće je uplatiti:",
            types: ["Mjesečni džeparac", "Džeparac za 3 mjeseca", "Džeparac za 6 mjeseci", "Džeparac za čitavu školsku godinu"]
          }
        ],
      tags: ["Donacije", "Škola", "Džaci", "Džeparac"],
      imageUrl: "assets/img/dzeparacZaSkolarceListing.jpg"
    },
    {
      id: 2,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Pomoć za sve",
      shortDescription: "Projekat je osnovan prvenstveno zbog pružanja pomoći najugroženijoj kategoriji stanovništva: siromašnoj djeci, samohranim majkama, porodicama u potrebi, bolesnim, gladnim, iznemoglim, učenicima u potrebi, onima kojima je pomoć potrebna!",
      description: "Ovaj fond je osnovan prvenstveno zbog pomoći najugroženijoj i najpotrebnijoj kategoriji stanovništva, a pogotovo siromašnoj djeci. Nakon zadovoljenja primarne funkcije ovog Fonda, a to je pomaganje najugroženijih, višak iz ovog Fonda se odvaja za troškove Fondacije - administrativne troškove (računovođe, prostor itd).",
      motivationMessage: "Ovaj projekat isključivo se finansira od dobrovoljnih priloga. Povećanjem našeg fonda svaki put biramo nove kandidate.",
      types: [
        {
          id: 0,
          description: "Uplate za ovaj fond su raspoređene za pomaganje:",
          types: [
            "Siromašne djece",
            "Porodica u potrebi",
            "Samohranih majki",
            "Bolesnih",
            "Gladnih",
            "Iznemoglih",
            "Učenika i studenata u potrebi",
            "Migranata",
            "Onima kojima je pomoć potrebna",
            "Za sve ostale humanitarne akcije"
          ]
        }
      ],
      tags: ["Donacije", "Pomoć za sve"],
      imageUrl: "assets/img/pomocZaSveListing.jpg"
    },
    {
      id: 3,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Predavanja i druženja",
      shortDescription: "Jedna od naših mnogobrojnih aktivnosti jesu i edukativna predavanja, radionice, seminari i druženja koja organizujuemo kako bismo motivisali mlade ljude da budu ambiciozni, uspješni, zacrtaju i najzad ostvare plemenite ciljeve!",
      description: "Biramo aktuelne teme današnjeg vremena. Do sada smo organizovali i predavanja čija je svrha bila olakšati učenje hifza i motivisati naše učenike da daju maksimum od sebe, da ne odustaju i da vjeruju da će doći do cilja uz Allahovu pomoć.",
      motivationMessage: "Kroz predavanja izgrađujemo i nas same kao snažnu građevinu koju vjetar današnjeg vremena ne može poljuljati i srušiti. Promovišemo pozitivne vrijednosti i društveni angažman.",
      tags: ["Predavanja", "Druženja"],
      imageUrl: "assets/img/predavanja.jpg"
    },
    {
      id: 4,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Program 'EN-NUR'",
      shortDescription: 'Aktivnost kojom se naročito ponosimo jeste i program "En-nur" - halka hifza za mušku i žensku populaciju, za sve uzraste, koje se održavaju u Sarajevu. Halka se održava vikendom tokom cijele godine, a istu vode članovi našeg tima, koji su ujedno istaknuti i kvalitetni hafizi/hafize. ',
      motivationMessage: "Cilj programa jeste usaditi u srca mladih ljudi ljubav prema Kur'anu, tako da im Kur'an postaje Nur (svjetlo) koje će ih voditi kroz život, uz Allahovu pomoć.",
      types: [
        {
          id: 0,
          description: "Sami program je koncipiran od više nivoa i kategeorija. Učesnici mogu birati da se priključe jednoj od slijedećih grupa:",
          types: [
            "Program za početnike",
            "Tedžvid kroz čitanje",
            "Hifz pojedinačnih kur'anskih sura",
            "Hifz cijelog Kur'ana"
          ]
        }
      ],
      tags: ["Halka Kur'ana", "Hifz", "Tedžvid"],
      imageUrl: "assets/img/enNurListing.jpg"
    },
    {
      id: 5,
      createdAt: "05 Dec, 2020",
      createdBy: "Fondacija Tadž",
      name: "Na putu hifza sa timom Tadž",
      shortDescription: "Veoma zanimljiva rubrika našim pratiteljima jesu i 'priče o hifzu'!",
      description: "Jednom sedmično objavljijemo priče i iskustva hafiza i onih koji su krenuli tim putem. Pišemo o istinitim događajima, o usponima i padovima, o odustajanju i ponovnom vraćanju hifzu, o odnosu društva i okoline prema osoboma koje uče hifz, ali i drugim stvarima.",
      motivationMessage: "Za sve one kojima ponestaje motivacije neka posjet rubriku priča i pronađu se u nekoj od objavljenih. A ukoliko žele da podijele svoje iskustvo i pomognu nekome ko želi doći do titule hafiza neka svoju priču pošalju na e-mail puthifza@gmail.com.",
      types: [
        {
          id: 0,
          description: "Sami program je koncipiran od više nivoa i kategeorija. Učesnici mogu birati da se priključe jednoj od sljedećih grupa:",
          types: [
            "Program za početnike",
            "Učenje Kur'ana po tedžvidu",
            "Hifz Amme džuza",
            "Hifz pojedinačnih kur'anskih sura",
            "Hifz cijelog Kur'ana"
          ]
        }
      ],
      tags: ["Halka Kur'ana", "Hifz", "Tedžvid"],
      imageUrl: "assets/img/priceListing.jpg"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
