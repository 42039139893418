<div class="navbar-light bg-light" [ngClass]="{'fixed-top' : isSticky }">
  <div class="container-full">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="d-flex justify-content-between w-100 navbar-relative">
        <div>
          <a class="navbar-brand logo-absolute" routerLink="" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
            <img src="../../assets/img/logo.png" alt="Logo" width="64" height="70">
          </a>
        </div>
        <div class="collapse navbar-collapse nav-coll" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink=""
                routerLinkActive="active"
                data-toggle="collapse"
                data-target=".navbar-collapse.show">
                POČETNA
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/oNama"
                routerLinkActive="active"
                data-toggle="collapse"
                data-target=".navbar-collapse.show">
                O NAMA
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/projekti"
                routerLinkActive="active"
                data-toggle="collapse"
                data-target=".navbar-collapse.show">
                PROJEKTI
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/kontakt"
                routerLinkActive="active"
                data-toggle="collapse"
                data-target=".navbar-collapse.show">
                KONTAKT
              </a>
            </li>
            <li class="nav-item btn-donate-mobile">
              <a
                class="nav-link"
                routerLink="/donacije"
                routerLinkActive="active"
                data-toggle="collapse"
                data-target=".navbar-collapse.show">
                <button type="button" class="btn btn-primary btn-lg">
                  <fa-icon [icon]="faHeart"></fa-icon>
                  DONIRAJ
                </button>
              </a>
            </li>
          </ul>
        </div>

        <a class="btn-donate" routerLink="/donacije" routerLinkActive="active">
          <button type="button" class="btn btn-primary btn-lg">
            <fa-icon [icon]="faHeart"></fa-icon>
            DONIRAJ
          </button>
        </a>
        <button class="navbar-toggler toggler-absolute" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </div>
</div>
