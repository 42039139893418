<!-- Start Breadcrumb
    ============================================= -->
    <div class="breadcrumb-area text-center shadow dark bg-fixed padding-xl text-light" style="background-image: url(assets/img/logo.png);">
      <div class="container">
          <div class="breadcrumb-items">
              <div class="row">
                  <div class="col-lg-12">
                      <h2>Donacije</h2>
                  </div>
              </div>
              <ul class="breadcrumb">
                  <li><a routerLink="" routerLinkActive="active"><fa-icon [icon]="faHome"></fa-icon>Početna</a></li>
                  <li>Donacije</li>
                  <!-- <li class="active">Donation</li> -->
              </ul>
          </div>
      </div>
  </div>
  <!-- End Breadcrumb -->

  <!-- Start Causes
  ============================================= -->
  <div class="causes-area bg-gray default-padding">
      <div class="container">
          <div class="causes-items">
              <div class="row" *ngFor="let project of projectList">
                  <!-- <div class="col-lg-12">  -->
                    <donate-row-entry [project]="project"></donate-row-entry>
                      <!-- Single Item -->
                      <!-- <div class="grid-item">
                          <div class="row">
                              <div class="thumb col-lg-5" style="background-image: url(../../assets/img/stipendiranje-listing.jpg);">
                                  <span class="cats">Stipendiranje budućih hafiza</span>
                              </div>
                              <div class="info col-lg-7">
                                  <div class="top-entry">
                                      <div class="date">
                                        <fa-icon [icon]="faClock"></fa-icon> <strong></strong> Jul 15, 2020
                                      </div>
                                      <div class="location">
                                        <fa-icon [icon]="faMapMarkerAlt"></fa-icon> <strong></strong>  Bosna i Hercegovina
                                      </div>
                                  </div>
                                  <h3>
                                      <a href="#">Stipendiranje budućih hafiza</a>
                                  </h3>
                                  <p>
                                    Projekat je osnovan sa ciljem da motivišemo što više učenika da uče Allahov govor i na taj način promovišemo pozitivne vrijednosti u društvu.
                                    Kroz projekat nastojimo pružiti financijsku podršku u vidu stipendije ali i moralnu podršku učenicima širom BiH, koja će biti njihov stimulans na putu hifza.
                                  </p> -->
                                  <!-- <div class="progress-box">
                                      <div class="progress">
                                          <div class="progress-bar" role="progressbar" data-width="33">
                                              <span>33%</span>
                                          </div>
                                      </div>
                                      <p>Raised : $5488 <span>Goal : $12800</span></p>
                                  </div> -->
                                  <!-- <a class="btn circle btn-theme border btn-md" href="#">Doniraj</a>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Single Item -->
                      <!-- Single Item -->
                      <!-- <div class="grid-item">
                          <div class="row">
                              <div class="thumb col-lg-5" style="background-image: url(../../assets/img/dzeparacZaSkolarceListing.jpg);">
                                  <span class="cats">Džeparac za školarce</span>
                              </div>
                              <div class="info col-lg-7">
                                  <div class="top-entry">
                                      <div class="date">
                                        <fa-icon [icon]="faClock"></fa-icon> <strong></strong> Aug 26, 2020
                                      </div>
                                      <div class="location">
                                        <fa-icon [icon]="faMapMarkerAlt"></fa-icon> <strong></strong>  Bosna i Hercegovina
                                      </div>
                                  </div>
                                  <h3>
                                      <a href="#">Džeparac za školarce</a>
                                  </h3>
                                  <p>
                                    Projekat smo pokrenuli s ciljem da pomognemo učenike osnovnih i srednjih škola koji žive u teškim uslovima.

                                    Cilj je pomoći socijalno-ugroženu djecu, djecu bez roditeljskog staranja, djecu u teškoj finansijskoj situaciji, a koja pohađaju osnovnu ili srednju školu.
                                  </p> -->
                                  <!-- <div class="progress-box">
                                      <div class="progress">
                                          <div class="progress-bar" role="progressbar" data-width="57">
                                              <span>57%</span>
                                          </div>
                                      </div>
                                      <p>Raised : $2890 <span>Goal : $5700</span></p>
                                  </div> -->
                                  <!-- <a class="btn circle btn-theme border btn-md" href="#">Doniraj</a>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Single Item -->
                      <!-- Single Item -->
                      <!-- <div class="grid-item">
                          <div class="row">
                              <div class="thumb col-lg-5" style="background-image: url(../../assets/img/pomocZaSveListing.jpg);">
                                  <span class="cats">Pomoć za sve</span>
                              </div>
                              <div class="info col-lg-7">
                                  <div class="top-entry">
                                      <div class="date">
                                        <fa-icon [icon]="faClock"></fa-icon> <strong></strong> Sep 06, 2020
                                      </div>
                                      <div class="location">
                                        <fa-icon [icon]="faMapMarkerAlt"></fa-icon> <strong></strong>  Bosna i Hercegovina
                                      </div>
                                  </div>
                                  <h3>
                                      <a href="#">Pomoć za sve</a>
                                  </h3>
                                  <p>
                                    Zbog velikog broja molbi za pomoć i zahtjeva za pokretanje humanitarnih akcija, sve humanitarne aktivnosti se financiraju iz jednog fonda koji nosi naziv "Pomoć za sve".
                                  </p> -->
                                  <!-- <div class="progress-box">
                                      <div class="progress">
                                          <div class="progress-bar" role="progressbar" data-width="68">
                                              <span>68%</span>
                                          </div>
                                      </div>
                                      <p>Raised : $5488 <span>Goal : $8900</span></p>
                                  </div> -->
                                  <!-- <a class="btn circle btn-theme border btn-md" href="#">Doniraj</a>
                              </div>
                          </div>
                      </div> -->
                      <!-- End Single Item -->
                  <!-- </div> -->
                  <!-- Paginatin -->
                  <!-- <div class="donation-pagi text-center col-lg-12">
                      <div class="row">
                          <div class="col-lg-12">
                              <nav aria-label="navigation">
                                  <ul class="pagination">
                                      <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-left"></i></a></li>
                                      <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                                      <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
                                  </ul>
                              </nav>
                          </div>
                      </div>
                  </div> -->
                  <!-- End Paginatin -->
              </div>
          </div>
      </div>
  </div>
  <!-- End Causes -->
