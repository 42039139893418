import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BannerComponent } from './banner/banner.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DonateDetailsComponent } from './donate/donate-details/donate-details.component';
import { DonateComponent } from './donate/donate.component';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';
import { ProjectsComponent } from './projects/projects.component';

const routes: Routes = [
  { path: '', component: BannerComponent },
  { path: 'donacije', component: DonateComponent },
  { path: 'donacije/:name', component: DonateDetailsComponent },
  { path: 'oNama', component: AboutComponent },
  { path: 'projekti', component: ProjectsComponent },
  { path: 'projekti/:name', component: ProjectDetailsComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'privacy', component: PrivacyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
