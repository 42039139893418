<!-- <div class="breadcrumb-area text-center shadow dark bg-fixed padding-xl text-light" style="background-image: url(assets/img/logo.png);">
  <div class="container">
      <div class="breadcrumb-items">
          <div class="row">
              <div class="col-lg-12">
                  <h2>O nama</h2>
              </div>
          </div>
          <ul class="breadcrumb">
              <li><a href="#"><i class="fas fa-home"></i> Početna</a></li>
              <li><a href="#">O nama</a></li>
              <li class="active">About</li>
          </ul>
      </div>
  </div>
</div> -->

<div class="about-area default-padding">
  <div class="container">
      <div class="row align-center">

          <div class="col-lg-6">
              <div class="thumb">
                  <img src="../../assets/img/oNama.jpg" alt="Logo">
                  <!-- <div class="overlay">
                      <h4>Više od 30+ stipendista</h4>
                  </div> -->
              </div>
          </div>

          <div class="col-lg-6 info">
              <h5>O nama</h5>
              <h2 class="text-blur">O_nama</h2>
              <h2 class="area-title">Fondacija za pomoć i edukaciju Tadž</h2>
              <p>
                Fondacija Tadž je neprofitna nevladina organizacija sa sjedištem u Sarajevu čiji su pokretači mladi ljudi koji žele promovisati opšte pozitivne vrijednosti u društvu.
                Fondacija se bavi stipendiranjem učenika ali i drugim aktivnostima humanitarnog karaktera.
              </p>
              <!-- <ul> -->
                  <!-- <li>
                      <h5>30+</h5>
                      <span>Stipendista</span>
                  </li>
                  <li>
                      <h5>30+</h5>
                      <span>Pomoć porodicama</span>
                  </li> -->
                  <!-- <li>
                      <h5>37K Sqmi.</h5>
                      <span>Ocean Proteced</span>
                  </li> -->
              <!-- </ul> -->
              <!-- <a class="btn circle btn-theme border btn-md" href="#">become a volunteer</a> -->
          </div>

      </div>
  </div>
</div>

<what-we-do></what-we-do>
<our-mission></our-mission>
<about-donation></about-donation>
<about-tadz></about-tadz>
