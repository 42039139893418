<div class="testimonials-area carousel-shadow default-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="site-heading text-center">
          <h5>Drugi o nama</h5>
          <h2>
            Šta drugi kažu <br> o Fondaciji Tadž
          </h2>
          <div class="heading-divider"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
              <!-- Single Item  -->
              <div class="item">
                <div class="info">
                  <div class="top-info">
                    <fa-icon [icon]="faQuoteLeft"></fa-icon>
                    <div class="provider">
                      <h4>Nezim Muderris Halilović</h4>
                      <span>Direktor Vakufske direkcije i rukovodilac ureda za hadž rijaseta IZ u BiH</span>
                    </div>
                  </div>
                  <p>
                    Svi plemeniti ljudi u svijetu ulažu i pomažu svoje naučnike, istražitelje i učenike, pa zbog čega ne bismo i mi pomogli ovaj vrijedni projekat?
                    Taj djelić nade i naše pomoći neće osiromašiti nas ni naše porodice, a izabranim kandidatima će biti stimulans da redovno uče, da budu odgovorni
                    i da na lakši način stignu do svoga cilja i postanu hafizi Kur'ana.
                  </p>
                </div>
              </div>
              <!-- End Single Item  -->
          </ng-template>
          <ng-template carouselSlide>
              <!-- Single Item  -->
              <div class="item">
                <div class="info">
                  <div class="top-info">
                    <fa-icon [icon]="faQuoteLeft"></fa-icon>
                    <div class="provider">
                      <h4>OŠ "Džemaludin Čaušević"</h4>
                      <span>JU</span>
                    </div>
                  </div>
                  <p>
                    Ove školske godine 2020/2021. naša škola sarađuje sa Fondacijom Tadž. To je neprofitna nevladina organizacija koja nastoji pomoći
                    u edukaciji učenika i studenata. Primarna djelatnost jeste sprovođenje projekata koji su humanitarnog karaktera, pa je tako u sklopu
                    projekta "Džeparac za školarce" određen broj učenika naše škole dobilo stipendiju.
                    Ovim putem se zahvaljujemo i nadamo se uspješnoj saradnji u budućnosti.
                  </p>
                </div>
              </div>
              <!-- End Single Item  -->
          </ng-template>
          <ng-template carouselSlide>
              <!-- Single Item  -->
              <div class="item">
                <div class="info">
                  <div class="top-info">
                    <fa-icon [icon]="faQuoteLeft"></fa-icon>
                    <div class="provider">
                      <h4>Nedim Botić</h4>
                      <span>Hafiz</span>
                    </div>
                  </div>
                  <p>
                    Mjesec ramazan je idealna prilika da podržimo ovaj hajirli projekat, Stipendiranje budućih hafiza. Ako ništa, makar da lajkamo i podijelimo stranicu.
                    Jer učenicima hifza je potrebna podrška. Lično poznajem neke od njih, koji su danas i hafizi, a koji su imali većih materijalnih problema tokom učenja, a
                    nažalost i danas ih imaju. A ima i onih talentovanih koji su baš zbog dunjalučkih problema i manjka podrške stali sa učenjem da bi se posvetili drugim stvarima.
                    Budimo tu jedni za druge i olakšajmo učenicima Kur'ana dunjalučke izazove. Allah da nagradi pokretače ovog projekta i da u njemu podari bereket i korist ljudima.
                    Amin.
                  </p>
                </div>
              </div>
              <!-- End Single Item  -->
          </ng-template>
          <ng-template carouselSlide>
            <!-- Single Item  -->
            <div class="item">
              <div class="info">
                <div class="top-info">
                  <fa-icon [icon]="faQuoteLeft"></fa-icon>
                  <div class="provider">
                    <h4>Hanka Vlahovljak</h4>
                    <!-- <span>Hafiz</span> -->
                  </div>
                </div>
                <p>
                  Hoćemo li skupa, makar share-om, pomoći jednu veoma lijepu ideju? Možda niste baš vi mogli postati hafiz, ali šta mislite da pomognete
                  i budete vjetar u leđa nekome ko je na putu da to postane?!
                  Imate priliku stipendirati buduće hafize, među kojima imamo hairli ljude koji su i slabijeg imovinskog stanja, pa bi im takva podrška
                  znatno pomogla da se posvete hifzu još jače i predanije.
                  Imate moju garanciju za cijeli tim Tadž koji vodi ovaj projekat.
                  Tadž je na arapskom jeziku kruna, a oni su naša kruna.
                </p>
              </div>
            </div>
            <!-- End Single Item  -->
        </ng-template>
        </owl-carousel-o>

        <!-- <div class="testimonial-items testimonial-carousel owl-carousel owl-theme"> -->
          <!-- Single Item  -->
          <!-- <div class="item">
            <div class="info">
              <div class="top-info">
                <fa-icon [icon]="faQuoteLeft"></fa-icon>
                <div class="provider">
                  <h4>Nezim Muderris Halilović</h4>
                  <span>Direktor Vakufske direkcije i rukovodilac ureda za hadž rijaseta IZ u BiH</span>
                </div>
              </div>
              <p>
                Svi plemeniti ljudi u svijetu ulažu i pomažu svoje naučnike, istražitelje i učenike, pa zbog čega ne bismo i mi pomogli ovaj vrijedni projekat?
                Taj djelić nade i naše pomoći neće osiromašiti nas ni naše porodice, a izabranim kandidatima će biti stimulans da redovno uče, da budu odgovorni
                i da na lakši način stignu do svoga cilja i postanu hafizi Kur'ana.
              </p>
            </div>
          </div> -->
          <!-- End Single Item  -->
          <!-- Single Item  -->
          <!-- <div class="item">
            <div class="info">
              <div class="top-info">
                <fa-icon [icon]="faQuoteLeft"></fa-icon>
                <div class="provider">
                  <h4>OŠ "Džemaludin Čaušević"</h4>
                  <span>JU</span>
                </div>
              </div>
              <p>
                Ove školske godine 2020/2021. naša škola sarađuje sa Fondacijom Tadž. To je neprofitna nevladina organizacija koja nastoji pomoći
                u edukaciji učenika i studenata. Primarna djelatnost jeste sprovođenje projekata koji su humanitarnog karaktera, pa je tako u sklopu
                projekta "Džeparac za školarce" određen broj učenika naše škole dobilo stipendiju.
                Ovim putem se zahvaljujemo i nadamo se uspješnoj saradnji u budućnosti.
              </p>
            </div>
          </div> -->
          <!-- End Single Item  -->
          <!-- Single Item  -->
          <!-- <div class="item">
            <div class="info">
              <div class="top-info">
                <fa-icon [icon]="faQuoteLeft"></fa-icon>
                <div class="provider">
                  <h4>Nedim Botić</h4>
                  <span>Hafiz</span>
                </div>
              </div>
              <p>
                Mjesec ramazan je idealna prilika da podržimo ovaj hajirli projekat, Stipendiranje budućih hafiza. Ako ništa, makar da lajkamo i podijelimo stranicu.
                Jer učenicima hifza je potrebna podrška. Lično poznajem neke od njih, koji su danas i hafizi, a koji su imali većih materijalnih problema tokom učenja, a
                nažalost i danas ih imaju. A ima i onih talentovanih koji su baš zbog dunjalučkih problema i manjka podrške stali sa učenjem da bi se posvetili drugim stvarima.
                Budimo tu jedni za druge i olakšajmo učenicima Kur'ana dunjalučke izazove. Allah da nagradi pokretače ovog projekta i da u njemu podari bereket i korist ljudima.
                Amin.
              </p>
            </div>
          </div> -->
          <!-- End Single Item  -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>

