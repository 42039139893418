<div class="banner-area inc-indicator content-less text-large" [ngStyle]="{ 'height': innerHeight }">
  <div id="bootcarousel" class="carousel text-light slide carousel-fade animate_text" data-ride="carousel" data-interval="7000" [ngStyle]="{ 'height': innerHeight }">

      <!-- Indicators for slides -->
      <div class="carousel-indicator">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <ol class="carousel-indicators">
                          <li data-target="#bootcarousel" data-slide-to="0" class="active"></li>
                          <li data-target="#bootcarousel" data-slide-to="1"></li>
                      </ol>
                  </div>
              </div>
          </div>
      </div>

      <!-- Wrapper for slides -->
      <div class="carousel-inner carousel-zoom">
          <div class="carousel-item active">
              <div class="slider-thumb bg-cover banner-image-primary" [ngStyle]="{ 'height': innerHeight }"></div>
              <div class="box-table">
                  <div class="box-cell shadow dark">
                      <div class="container container-content" [ngStyle]="{ 'top': containerTop }">
                          <div class="row">
                              <div class="col-lg-9">
                                  <div class="content">
                                      <h2 data-animation="animated slideInRight"></h2>
                                      <!-- <p data-animation="animated slideInLeft">
                                        "Ničim se ne možete približiti Allahu kao što to možete Kur'anom!" (Hadis)
                                      </p> -->
                                      <h2 data-animation="animated fadeInUp" ></h2>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="carousel-item">
              <div class="slider-thumb bg-cover banner-image-secondary" [ngStyle]="{ 'height': innerHeight }"></div>
              <div class="box-table">
                  <div class="box-cell shadow dark">
                      <div class="container container-content" [ngStyle]="{ 'top': containerTop }">
                          <div class="row">
                              <div class="col-lg-9">
                                  <div class="content">
                                      <h2 data-animation="animated slideInRight">Pomoć<strong>za sve</strong></h2>
                                      <!-- <p data-animation="animated slideInLeft">
                                        "Šta god vi udijelite, On će  to nadoknaditi. On najbolje opskrbljuje." (Kur'an)
                                      </p> -->
                                      <a data-animation="animated fadeInUp" class="btn circle btn-light btn-md" routerLink="/projekti/Pomoć za sve" routerLinkActive="active">Više o projektu</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- End Wrapper for slides -->

  </div>
</div>

<about></about>
