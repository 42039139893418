import { Component, OnInit } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss', '../app.component.scss']
})
export class ContactComponent implements OnInit {
  faHome = faHome;
  faMapMarkerAlt = faMapMarkerAlt;
  faEnvelopeOpen = faEnvelopeOpen;
  faMobileAlt = faMobileAlt;

  constructor() { }

  ngOnInit(): void {
  }

}
