<!-- <div class="se-pre-con"></div> -->

<div class="hibiscus">
  <div *ngIf="mobile">
    <header></header>
  </div>
  <navbar></navbar>
  <router-outlet></router-outlet>
  <footer-bottom></footer-bottom>
</div>
